<template>
	<el-dialog title="报警详情" :visible.sync="detailDialog" width="600px">
		<div>
			<div class="video-list">
				<div class="video-item" v-for="(item, index) in videoList" :key="index" :class="videoList.length > 1 ? 'multiple' : ''">
					<video class="video-box" :controls="true">
						<source :src="item.outLinkUrl" />
					</video>
					<el-button
						type="primary"
						@click="downItem(item)"
						class="down-btn"
						v-if="btnexist('scheduleSecureDsmDownload') && videoList.length > 1"
					>
						下载到本地
					</el-button>
				</div>
				<p v-if="!videoList.length">无视频数据</p>
			</div>
			<!-- <video-player
				v-if="detailDialog"
				:stream="false"
				:channel="false"
				:isHistory="true"
				:startTime="startTime"
				:endTime="endTime"
				ref="vplayer"
				class="vplayer"
				:params="{ clientId: rowData.deviceId, channelId: 1 }"
			></video-player> -->
		</div>
		<div slot="footer">
			<el-button @click="detailDialog = false">关闭</el-button>
			<!-- 先隐藏 -->
			<el-button type="primary" @click="downLoadHandle" v-if="btnexist('scheduleSecureDsmDownload') && videoList.length === 1"
				>下载到本地</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
// import VideoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
import { btnMixins } from "@/common/js/button.mixin";
import dayjs from "dayjs";

export default {
	data() {
		return {
			btnMenuId: "scheduleSecureDsm",
			videoUrl: "",
			startTime: "",
			endTime: "",
			videoList: []
		};
	},
	/* components: {
		VideoPlayer
	}, */
	props: {
		value: Boolean,
		rowData: {
			type: Object,
			default: () => ({})
		}
	},
	mixins: [btnMixins],
	computed: {
		detailDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},
	watch: {
		detailDialog(val) {
			if (val) {
				// this.init();
				this.getData();
			} else {
				this.videoList = [];
			}
		}
	},
	/* created() {
		this.getData();
	}, */
	methods: {
		init() {
			this.startTime = dayjs(this.rowData.alarmBeginTime).subtract(5, "second").format("YYYYMMDDHHmmss");
			this.endTime = dayjs(this.rowData.alarmBeginTime).add(5, "second").format("YYYYMMDDHHmmss");
		},
		getData() {
			const url = `/crrc-palm-bus/safetyManagement/alarmAttachment/list`;
			const params = { alarmCode: this.rowData.alarmCode, attachmentType: "02" };
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				const data = res?.records || [];
				this.videoList = data;
			});
		},
		downLoadHandle() {
			location.href = this.videoList[0].outLinkUrl;
			// this.$util.funDownload(res.data, "报警信息", "video");
		},
		downItem(obj) {
			location.href = obj.outLinkUrl;
		}
	}
};
</script>

<style lang="scss" scoped>
.video-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	.video-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		&.multiple {
			width: calc((100% - 10px) / 2);
		}
		.video-box {
			width: 100%;
		}
		.down-btn {
			margin-top: 10px;
		}
	}
}
</style>
